<script setup lang="ts">
import {useDomainDataStore} from "~/stores/domain"

const domainData = useDomainDataStore()
</script>

<template>
  <div class="min-h-screen flex flex-col bg-domain-dark p-bento gap-bento" :class="domainData.domainClass">
    <NuxtLink class="bg-white rounded-bento p-8 text-center" :to="localePath('index')">
      <img class="inline-block w-64" src="/assets/images/logo-b.svg" width="484" height="100" :alt="$t('header.logo_alt')">
    </NuxtLink>
    <div class="bg-white rounded-bento p-10 grow flex justify-center items-center">
      <slot />
    </div>
  </div>
</template>